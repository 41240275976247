const EDIT = 'edit';
const CREATE = 'create';
const VIEW = 'view';

const getTypeForm = (str: string) => {
  if (!str) return '';
  if (str?.includes(EDIT)) {
    return EDIT;
  }
  if (str?.includes(CREATE)) {
    return CREATE;
  }
  if (str?.includes(VIEW)) {
    return VIEW;
  }
  return '';
};

const createMarkup = (html: string) => {
  return {__html: html};
}


export {getTypeForm, CREATE, VIEW, EDIT, createMarkup};