import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Flex, MenuProps, Select, Typography } from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import style from '../../../style.module.scss';
import { messagesModal } from '../../../../../../constants';
import { RouteNames } from '../../../../../../router/names';
import { moreIcon } from '../../../../../../assets/image/svg';
import { ITabView, IValues } from '../../../../../../types/deals';
import { fileTypes } from '../../../../../../helpers/input-helpers';
import { openInNewTab } from '../../../../../../helpers/link-helper';
import { FORMAT_DATE_KTK, nameColumnsKtk } from '../../../constants';
import { RootState, useStoreDispatch } from '../../../../../../store';
import UploaderDocs from '../../../../../../components/ui/UploaderDocs';
import { handleDownloadXlsx } from '../../../../../../helpers/xlsx-helper';
import ModalConfirm from '../../../../../../components/ui/ModalConfirm/ModalConfirm';
import { FormDatePicker, FormInputNumber } from '../../../../../../components/ui/Form';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import { deleteContainerDeal, editContainerDeal, editDealContainerList, IContainers } from '../../../../../../store/sales';

const { title, subtitle } = messagesModal.delete;

const TabDoc: React.FC<ITabView> = ({ deal }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [deleteContainer, setDeleteContainer] = React.useState<IContainers>();
  const [values, setValues] = React.useState<IValues>({});
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.sales.containerDealStatuses,
  ) as { id: number; value: string }[];
  const containers = useSelector<RootState>(
    (state) => state.sales.containers,
  ) as IContainers[];

  const handlerUpload = () => {
    const rows = containers.map(
      ({ num_container, type, date_issue, status, bet, price }) => ({
        num_container,
        type,
        date_issue,
        status,
        bet,
        price,
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк сделки')} ${deal.id_deal ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const editContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...containers[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...result[index], isEdit: true };
    dispatch(editDealContainerList(result));
  };
  const deletedContainer = () => {
    dispatch(
      deleteContainerDeal({
        id: deleteContainer?.id as number,
        id_deal: deal.id as number,
      }),
    );
  };
  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
    isEdit?: boolean,
  ): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Редактировать') }}
          propsButton={{
            onClick: () => editContainer(index),
            icon: <EditOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
    {
      key: '2',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Удалить') }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
  ];

  const submitContainer = (index: number) => {
    const result = [...containers];
    result[index] = { ...values[index], isEdit: false };
    const id = result[index]?.id;
    if (id) {
      dispatch(editContainerDeal({ container: result[index], index }));
    }
  };
  const closeEditContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...containers[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...result[index], isEdit: false };
    dispatch(editDealContainerList(result));
  };

  const handlerChange = ({
    name,
    value,
    index,
  }: {
    name: string;
    value: any;
    index: number;
  }) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [name]: value,
      },
    }));
  };

  return (
    <Flex gap={30} vertical align="flex-start">
      <div className={style['file-box']}>
        <UploaderDocs
          isView
          ids={deal?.docs}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
        />
      </div>

      <div className={style['overlap-group-deal']}>
        <div className={style['table']}>
          <div className={style['table__container']}>
            <div className={style['table__header']}>
              {nameColumnsKtk.map((title) => (
                <div
                  key={title}
                  className={classnames(
                    style['table__cell'],
                    style['label-card-16'],
                  )}
                >
                  {t(title)}
                </div>
              ))}

              <div className={style['menu-icon']}>
                <div className={style['label-card-16']}>
                  <Dropdown
                    overlayClassName={style.drop}
                    menu={{
                      items: [
                        {
                          key: 'link2',
                          label: (
                            <Typography.Text
                              data-id="link"
                              className={classnames(style['menu-icon__link'])}
                              onClick={handlerUpload}
                              data-disabled={!containers?.length}
                              disabled={!containers?.length}
                            >
                              {t('Выгрузить в Excel')}
                            </Typography.Text>
                          ),
                        },
                      ],
                    }}
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <Typography.Link
                      className=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <Icon component={moreIcon} />
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className={style['table__body']}>
              {containers.map((field, index) => (
                <div key={field.num_container} className={style['table__row']}>
                  <div className={classnames(style['table__cell'])}>
                    <Typography.Link
                      className={classnames(style['text-card-14'], {
                        [style['default-link']]: field?.['container_id'],
                      })}
                      ellipsis
                      onClick={() =>
                        openInNewTab(
                          `${RouteNames.CONTAINERS_VIEW}${field?.['container_id']}`,
                        )
                      }
                    >
                      {field?.['num_container']}
                    </Typography.Link>
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['type']}
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['isEdit'] ? (
                      <FormDatePicker
                        format={FORMAT_DATE_KTK}
                        value={field?.['date_issue'] || null}
                        handlerChange={(value: string | null) => {
                          handlerChange({
                            name: 'date_issue',
                            value,
                            index,
                          });
                        }}
                      />
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['date_issue'] || t('Добавьте дату'),
                          },
                        }}
                      >
                        {field?.['date_issue'] || t('Добавьте дату')}
                      </Typography.Text>
                    )}
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['isEdit'] ? (
                      <Select
                        placeholder={t("Добавьте статус")}
                        style={{ width: '100%' }}
                        defaultValue={field.status_id}
                        onChange={(value) => {
                          const status =
                            containerDealStatuses.find(({ id }) => id === value)
                              ?.value || null;
                          handlerChange({
                            name: 'status',
                            value: status,
                            index,
                          });
                          handlerChange({ name: 'status_id', value, index });
                        }}
                        options={containerDealStatuses?.map(
                          ({ id, value }) => ({ value: id, label: value }),
                        )}
                      />
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['status'] || t('Добавьте статус'),
                          },
                        }}
                      >
                        {field?.['status'] || t('Добавьте статус')}
                      </Typography.Text>
                    )}
                  </div>
                  <div className={classnames(style['table__cell'])}>
                    <Typography.Text
                      className={style['text-card-14']}
                      ellipsis={{
                        tooltip: {
                          placement: 'topLeft',
                          title: field?.['bet'] ?? '',
                        },
                      }}
                    >
                      {field?.['bet'] ?? ''}
                    </Typography.Text>
                  </div>
                  <div className={classnames(style['table__cell'])}>
                    {field?.['isEdit'] ? (
                      <FormInputNumber
                        name={'price'}
                        placeholder={t("Добавьте цену")}
                        value={values[index]['price']}
                        controls={false}
                        min={0}
                        maxLength={15}
                        onChange={(val) =>
                          handlerChange({
                            name: 'price',
                            value: val,
                            index,
                          })
                        }
                      />
                    ) : (
                      <Typography.Text
                        className={style['text-card-14']}
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['price'] || t('Добавьте цену'),
                          },
                        }}
                      >
                        {field?.['price'] || t('Добавьте цену')}
                      </Typography.Text>
                    )}
                  </div>
                  <Flex className={style['table__menu']} align="center">
                    <div className={style['text-card-14']}>
                      {field?.['isEdit'] ? (
                        <Dropdown
                          overlayClassName={style.drop}
                          open
                          menu={{
                            items: [
                              {
                                key: '1',
                                label: (
                                  <TooltipButton
                                    propsTooltip={{ title: t('Сохранить') }}
                                    propsButton={{
                                      className: style['table__button'],
                                      shape: 'circle',
                                      icon: <CheckOutlined />,
                                      onClick: () => submitContainer(index),
                                    }}
                                  />
                                ),
                              },
                              {
                                key: '2',
                                label: (
                                  <TooltipButton
                                    propsTooltip={{ title: t('Закрыть') }}
                                    propsButton={{
                                      className: style['table__button'],
                                      shape: 'circle',
                                      icon: <CloseOutlined />,
                                      onClick: () => closeEditContainer(index),
                                    }}
                                  />
                                ),
                              },
                            ],
                            style: {
                              background: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Typography.Link
                            className=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <Icon component={moreIcon} />
                          </Typography.Link>
                        </Dropdown>
                      ) : (
                        <Dropdown
                          overlayClassName={style.drop}
                          menu={{
                            items: getItems(
                              () => {
                                setConfirmation(true);
                                setDeleteContainer(field);
                              },
                              editContainer,
                              index,
                            ),
                            style: {
                              background: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Typography.Link
                            className=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <Icon component={moreIcon} />
                          </Typography.Link>
                        </Dropdown>
                      )}
                    </div>
                  </Flex>
                </div>
              ))}
              <ModalConfirm
                title={title}
                subtitle={subtitle}
                isOpen={confirmation}
                closeModal={() => setConfirmation(false)}
                actionAfterConsent={deletedContainer}
              />
            </div>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default TabDoc;
