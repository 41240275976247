// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_input__BznXC {
  padding-right: 10px;
}
.style_input__icon__wD7qt {
  width: 11px;
  translate: -25px 0;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Form/FomInputNumber/style.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,eAAA;AACJ","sourcesContent":[".input {\n  padding-right: 10px;\n\n  &__icon {\n    width: 11px;\n    translate: -25px 0;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `style_input__BznXC`,
	"input__icon": `style_input__icon__wD7qt`
};
export default ___CSS_LOADER_EXPORT___;
