import socket from "../socket";

export class Rest_terminal {

    static searchTerminalCity(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('search-terminal-city',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTerminalCity(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-terminal-city',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static findTerminalCity(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-terminal-city',payload, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static findTerminalChildCity(payload: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-terminal-child-city',payload, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static setTerminalCity(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('set-terminal-city',payload)
        })
    }

    static setTerminal(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('set-terminal',payload)
        })
    }

    static getTerminal(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-terminal',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static findTerminalOne(id: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-terminal-one',id,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static refTerminal(payload: {}): Promise<any>{
        return new Promise(async () => {
            socket.emit('ref-terminal',payload)
        })
    }

    static deleteTerminal(payload: {}): Promise<any>{
        return new Promise(async () => {
            socket.emit('delete-terminal',payload)
        })
    }

    static getFiltersTerminals(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-terminals',payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }
}
