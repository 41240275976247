import dayjs from 'dayjs';

const disabledDateFromStartToEnd = (
  current: any,
  start: dayjs.ConfigType,
  end?: dayjs.ConfigType,
) => {
  const date = dayjs(start).subtract(1, 'day');
  if (current < dayjs(date)) {
    return true;
  } if (current > dayjs(end) && end) {
      return true;
  } return false;
};

const checksDatePeriod = (date: dayjs.Dayjs, day=1) => {
  return dayjs(date).add(day, "day") <= dayjs();
}
const checksDatePeriodComment = (date?: dayjs.Dayjs | string, day=1) => {
  return !(dayjs(date).add(day, "day") <= dayjs());
}

export {checksDatePeriod, disabledDateFromStartToEnd, checksDatePeriodComment};
