import { useRef, useState } from 'react';
import React, { useEffect, useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Flex, Form, Modal, Select, Typography } from 'antd';

import TabDoc from './tabs/edit/TabDoc';
import style from '../style.module.scss';
import { Utils } from '../../../../utils';
import TabDeal from './tabs/edit/TabDeal';
import Responsible from './list/Responsible';
import { IFormDeal } from '../../../../types/deals';
import { messagesModal } from '../../../../constants';
import { TAB_DEAL, TAB_DOCUMENT } from '../constants';
import { findExecutor } from '../../../../store/contact';
import ScrollList from '../../../../components/ui/ScrollList';
import { RootState, useStoreDispatch } from '../../../../store';
import { ITab, ITabs } from '../../../../components/ui/Tabs/Tabs';
import { clearSearchCompany } from '../../../../store/contractors';
import ModalConfirm from '../../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import { SelectSearch } from '../../../../components/ui/SelectSearch/SelectSearch';
import { generateIdDeal, IDeal, setDocsDeal, setEditDeal, setIsEditDealContainers, setResponsible, setSaveFiles } from '../../../../store/sales';

const dealNames = ['name_deal', 'my_company', 'company'];
const docNames = ['doc'];

const FormDeal: React.FC<IFormDeal> = ({
  onClose,
  onFinish,
  isOpen,
  title,
  initialValue,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const refResponsible = useRef(null);
  const [form] = Form.useForm();
  // const typeDeal = Form.useWatch('type_deal', { form, preserve: true });
  const [activeKey, setActiveKey] = useState(() => TAB_DEAL);
  const [searchValue, setSearchValue] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isErrorsDeal, setIsErrorsDeal] = React.useState(false);
  const [isErrorsDoc, setIsErrorsDoc] = React.useState(false);
  const [dataExecutor, setDataExecutor] = useState<any[]>([]);
  const [isEditResponsible, setIsEditResponsible] = useState(() => false);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);
  const [isModalOpenResponsible, setIsModalOpenResponsible] =
    useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const saveFiles: any = useSelector<RootState>(
    (state) => state.sales.saveFiles,
  );
  const idDeal = useSelector<RootState>(
    (state) => state.sales.idDeal,
  ) as string;
  const isEditDeal = useSelector<RootState>((state) => state.sales.isEditDeal);
  const responsible = useSelector(
    (state: RootState) => state.sales.responsible,
  );
  const dealType = useSelector<RootState>((state) => state.sales.dealType) as {
    id: number;
    value: string;
    char: string;
  }[];
  const isEditDealContainers = useSelector<RootState>(
    (state) => state.sales.isEditDealContainers,
  ) as boolean;

  // HACK: type IDeal
  const defaultValues: IDeal = {
    id_deal: '',
    type_deal: 'Закуп',
    type_deal_id: 3,
    type_deal_char: 'ПЗ',
    name_deal: '',
    phone: '',
    account_num: '', // номер счета
    my_company: '',
    my_company_id: undefined,
    company: '',
    company_id: undefined,
    contact_id: undefined,
    currency: '',
    currency_id: undefined,
    nds: undefined,
    bet: undefined,
    responsible: [],
    containers: [],
    containers_ids: [],
    comment: '',
    comment_last: '',
    comments: [],
    docs: [],
    route: ['', ''],
  };

  const selectedType = (val: string | null, opt: any) => {
    form.setFieldValue('type_deal', opt?.label ?? '');
    form.setFieldValue('type_deal_char', `П${opt?.char || ''}`);
    form.setFieldValue('id_deal', `П${opt?.char || ''} ${idDeal}`);
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label:'Сделка',
        forceRender: true,
        isError: isErrorsDeal,
        disabledText: isEditDealContainers ? t('Завершите редактирование') : '',
        disabled: isEditDealContainers,
        content: <TabDeal form={form} isDisabled={isDisabled} />,
      },
      {
        key: TAB_DOCUMENT,
        label: 'Документы',
        forceRender: true,
        isError: isErrorsDoc,
        content: <TabDoc form={form} isOpen isDisabled={isDisabled} />,
      },
    ],
    [
      isErrorsDoc,
      isErrorsDeal,
      form,
      isEditDeal,
      isEditDealContainers,
    ],
  );

  const onSubmit = async (values: any) => {
    try {
      const { containers, comments, ...rest } = values;
      const route = rest?.route || [];
      rest.comment = '';
      const submit = {
        deal: {
          ...initialValue,
          ...rest,
          responsible,
          route,
          routeText: route.join('\n'),
          status_id: initialValue?.id ? 2 : 1,
          saveFiles,
        },
        containers,
        comments,
      };
      if (onFinish) {
        onFinish(submit);
        dispatch(setResponsible([]));
        dispatch(setDocsDeal([]));
        dispatch(setSaveFiles([]));
      }
    } catch (err) {
      console.error('submit', err);
    }
  };
  const onUpdate = async (changedValues: any, allValues: any) => {
    setIsErrorsDeal(false);
    setIsErrorsDoc(false);
    return;
  };
  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const [title] = errors;
        if (isComplectCheckErrors.length === 2) {
          break;
        }
        if (dealNames.includes(title)) {
          setIsErrorsDeal(true);
        }
        if (docNames.includes(title)) {
          setIsErrorsDeal(true);
        }
      }
    }

    return;
  };

  const handleClose = () => {
    const promise = new Promise((resolve, reject) => {
      resolve(dispatch(setEditDeal(false)));
      resolve(dispatch(clearSearchCompany()));
      resolve(dispatch(setResponsible([])));
    });
    promise.then(() => {
      if (onClose) {
        onClose();
      }
      setIsErrorsDeal(false);
      setIsErrorsDoc(false);
      form.resetFields();
    });
  };

  const addFormResponsible = () => {
    const respUser = { ...responsibleChange };
    respUser.isResponsible = isEditResponsible;
    if (isEditResponsible) {
      form.setFieldValue('responsible_id', respUser?.id);
      form.setFieldValue('responsible_name', respUser?.name);
    }
    const filter = responsible
      ?.filter((item: any) => item?.id !== respUser?.id)
      .map((item: any) => {
        const current = { ...item, isResponsible: false };
        return current;
      });
    dispatch(
      setResponsible(
        isEditResponsible ? [respUser, ...filter] : [...responsible, respUser],
      ),
    );
    setIsModalOpenResponsible(false);
    setIsEditResponsible(false);
    setResponsibleChange(null);
    setSearchValue(null);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 2) {
      const result: any = await dispatch(findExecutor(event.target.value));
      const currentResult = result?.payload?.filter(({ id }: { id: number }) =>
        isEditResponsible
          ? responsible?.[0]?.id !== id
          : responsible?.findIndex((item) => item?.id === id) === -1,
      );

      if (currentResult.length > 0) {
        setDataExecutor(currentResult);
      }
    }
  };

  const handleAddResponsible = (e: any, option: any) => {
    const resp = {
      avatar: option.avatar,
      name: option.children,
      id: option.value,
    };
    setResponsibleChange(resp);
    setDataExecutor([]);
    setSearchValue(option.children);
  };

  const showModalResponsible = () => {
    setIsModalOpenResponsible(true);
  };

  const handleCancelResponsible = () => {
    setSearchValue(null);
    setIsModalOpenResponsible(false);
    setIsEditResponsible(false);
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (isDisabled) {
      form.setFieldsValue({ ...defaultValues, ...initialValue });
      dispatch(setResponsible(initialValue?.responsible));
    } else {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };
      dispatch(generateIdDeal(defaultValues.type_deal_char)).finally(() => {
        dispatch(setResponsible(Utils.getUserInfo().fio ? [initUser] : []));
        const responsibleInfo = {
          responsible_id: initUser.id,
          responsible_name: initUser.name,
        };
        defaultValues.id_deal = `${defaultValues.type_deal_char} ${idDeal}`;
        form.setFieldsValue({ ...defaultValues, ...responsibleInfo });
      });
    }
    form.resetFields();
  }, [isDisabled, isOpen, idDeal]);

  useEffect(() => {
    dispatch(setEditDeal(true));
    return () => {
      dispatch(setEditDeal(false));
      dispatch(setIsEditDealContainers(false));
    };
  }, []);

  return (
    <>
      <Form
        className={style['tabs-form']}
        autoComplete="off"
        noValidate
        layout="vertical"
        form={form}
        initialValues={
          isDisabled ? { ...defaultValues, ...initialValue } : defaultValues
        }
        scrollToFirstError
        onFinish={onSubmit}
        onFinishFailed={onFinishError}
        onValuesChange={onUpdate}
      >
        <Flex
          justify="space-between"
          align="center"
          style={{ marginBottom: 24 }}
        >
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>{t(title ?? '')}</Typography.Text>
          </Flex>
          <Col span={8}>
            <Flex vertical>
              <Typography.Text className={style['text-card-12']}>
                {t('Тип сделки')}
              </Typography.Text>

              <Form.Item name={'type_deal'} noStyle />
              <Form.Item name={'type_deal_char'} noStyle />
              <Form.Item
                name={'type_deal_id'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Select
                  placeholder={t('Выберите тип сделки')}
                  options={dealType?.map(({ id, value, char }) => ({
                    value: id,
                    label: value,
                    char,
                  }))}
                  disabled={isDisabled}
                  onSelect={selectedType}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Flex>

        <ITabs
          className={style['tabs-form__tab']}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
        <ModalConfirm
          title={messagesModal.warning.title}
          subtitle={messagesModal.warning.subtitle}
          isOpen={confirmation}
          closeModal={() => setConfirmation(false)}
          actionAfterConsent={
            isModalOpenResponsible ? handleCancelResponsible : handleClose
          }
        />
        <Flex gap={6} vertical>
          <Typography.Title level={4} className={style['subtitle']}>
            {t('Дополнительно')}
          </Typography.Title>
          <Typography.Text style={{ marginTop: 0 }}>
            {t('Ответственный')}
          </Typography.Text>
          <Form.Item name="containers_ids" noStyle />
          <Form.Item name="responsible" noStyle />
          <Form.Item name="responsible_id" noStyle />
          <Form.Item name="responsible_name" noStyle />
          {!!responsible?.length && (
            <ScrollList
              tableRef={refResponsible}
              setLastItemReached={(v) => v}
              height={150}
              totalTableActivity={1}
              data={responsible}
              paginationActivity={() => {}}
              isTable={false}
              endMessage={null}
            >
              {responsible.map((item, index, items) =>
                item?.name ? (
                  <Flex
                    key={index}
                    justify={'space-between'}
                    align={'baseline'}
                    style={{ paddingRight: 7, minWidth: '400px' }}
                  >
                    <Responsible
                      title={item?.name}
                      description={
                        item?.isResponsible ? t('Ответственный') : ''
                      }
                      avatar={item?.avatar}
                    />
                    <Flex vertical gap={6}>
                      {!index && (
                        <>
                          <Typography.Text
                            style={{
                              marginTop: 0,
                              color: '#05F',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setIsEditResponsible(true);
                              showModalResponsible();
                            }}
                          >
                            {t('Сменить ответственного')}
                          </Typography.Text>
                          <Typography.Text
                            style={{
                              marginTop: 0,
                              color: '#05F',
                              cursor: 'pointer',
                            }}
                            onClick={showModalResponsible}
                          >
                            {t('Добавить ответственного')}
                          </Typography.Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                ) : null,
              )}
            </ScrollList>
          )}
        </Flex>
        <Form.Item>
          <Flex
            gap={8}
            justify="space-between"
            style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
          >
            <TooltipButton
              propsTooltip={{
                title: isEditDealContainers
                  ? t('Завершите редактирование')
                  : initialValue?.id
                  ? t('Сохранить изменения')
                  : t('Добавить'),
              }}
              propsButton={{
                type: 'primary',
                htmlType: 'submit',
                style: { maxWidth: '290px', minWidth: '290px' },
                disabled: isEditDealContainers,
              }}
            >
              {initialValue?.id ? t('Сохранить изменения') : t('Добавить')}
            </TooltipButton>
            <Button
              onClick={() => setConfirmation(true)}
              type={'text'}
              style={{ maxWidth: '134px', color: '#E14453' }}
            >
              {t('Отменить')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
      <Modal
        className={classnames(style.modal, {
          [style.modal_hidden]: confirmation,
        })}
        title={`${isEditResponsible ? t('Сменить') : t('Добавить')} ${t(
          'ответственного',
        )}`}
        open={isModalOpenResponsible}
        onCancel={handleCancelResponsible}
        footer={null}
      >
        <Flex vertical style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            type={'page'}
            defaults={searchValue}
            dataSource={dataExecutor}
            notFoundContent={t('Пользователь не найден')}
            style={{ width: '100%' }}
            dropdownRender={onNameChange}
            onChangeSelect={handleAddResponsible}
            placeholder={t('Ответственный')}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
              disabled={!responsibleChange}
            >
              {t('Добавить')}
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              {t('Отменить')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default FormDeal;
