const TAB_DEAL = 'tabDeal';
const TAB_DOCUMENT = 'tabDocument';
const FORMAT_DATE_KTK = 'DD.MM.YYYY';

// ктк для excel
const nameColumnsKtk = [
  '№КТК',
  'Тип',
  'Дата выдачи',
  'Статус',
  'Входящая ставка',
  'Стоимость',
];
const keyNamesKtk = [
  'num_container',
  'type',
  'date_issue',
  'status',
  'bet',
  'price',
];

const statusColorsDeal = [
  {
    title: 'Новый',
    color: '#00B288',
    background: '#E6F6EC',
  },
  {
    title: 'Ожидаем оплаты',
    color: '#F29100',
    background: '#FEF4E6',
  },
  {
    title: 'Выдан без оплаты',
    color: '#445371',
    background: '#EDEEF1',
  },
  {
    title: 'Продан',
    color: '#0055FF',
    background: '#F2F6FF',
  },
  {
    title: 'Просрочено',
    color: '#E14453',
    background: '#FCE8EA',
  },
];
enum CURRENCY_TYPE {
  '&#8381;' = 2, // рубль
  '&#36;' = 3, // доллар
  '&#8364;' = 4, // евро

}

export { TAB_DEAL, TAB_DOCUMENT, statusColorsDeal, CURRENCY_TYPE, FORMAT_DATE_KTK, nameColumnsKtk, keyNamesKtk };
