import { useEffect, useState } from 'react';

import { ConfigProvider, Layout } from 'antd';
import { BrowserRouter, useHistory } from 'react-router-dom';

import { Utils } from './utils';
import Loading from './pages/Loading';
import { setAuth } from './store/users';
import { useStoreDispatch } from './store';
import { RouteNames } from './router/names';
import AppRouter from './components/AppRouter';
import { useLocalizeDocumentAttributes } from './hooks';

import './assets/scss/index.scss';

function App() {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  useLocalizeDocumentAttributes();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    if (Utils.getStorage('_in') === null) {
      dispatch(setAuth(false));
      history.push(RouteNames.LOGIN);
    } else {
      dispatch(setAuth(true));
    }
    setIsLoading(false);
  }, [dispatch, history]);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextBase: '#0E1117',
          colorText: '#0E1117',
          colorTextLabel: '#a3a9b1',
          fontFamily:
            "-apple-system, Manrope, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        },
      }}
    >
      <Layout style={{ height: 'calc(100vh - 0)' }}>
        <Layout.Content>
          {isLoading ? (
            <Loading />
          ) : (
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          )}
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
