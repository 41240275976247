import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import users from "./users";
import companies from "./contractors"
import types from "./types"
import contact from "./contact"
import containers from './containers'
import terminals from './terminals'
import stores from './store'
import park from './park'
import sales from "./sales";
import filters from './filters'
import rent from "./rent";

export const store = configureStore({
    reducer: {
        users,companies,types,contact,containers,terminals,stores,park,
        sales,filters, rent,
    },
    devTools: true,
});
export const useStoreDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>
