import { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Button, Divider, Flex, Layout } from 'antd';

import {delay} from 'lodash';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';

import Headers from '../Header';
import Menus from '../Menu/Menu';
import socket from '../../socket';
import { Utils } from '../../utils';
import { Helpers } from '../../helpers';
import style from './style.module.scss';
import { RouteNames } from '../../router/names';
import { setSourceContact } from '../../store/contact';
import DrawerRight from '../ui/DrawerRight/DrawerRight';
import { findMyCompany } from '../../store/contractors';
import { RootState, useStoreDispatch } from '../../store';
import { entitiesInfo, setAuth } from '../../store/users';
import { setTerminalDataCity } from '../../store/terminals';
import { getCurrency, getPercent, setLang } from '../../store/store';
import TooltipButton from '../ui/TooltipButton/TooltipButton';
import { ExitIcon, notificationIcon } from '../../assets/image/svg';
import { getResources, setStatusContainer } from '../../store/containers';
import { getContainerDealStatuses, getTypeDeal } from '../../store/sales';
import { setDataCurrency, setDrawer, setFetchedResources } from '../../store/store';
import { setTypeCompany, setTypeContainers, setTypeEmail, setTypeMessenger, setTypePhone, setTypeQuality } from '../../store/types';

const { Sider, Content } = Layout;

const Layouts = (props: any) => {
  const { t } = useTranslation();
  const router = useHistory();
  const dispatch = useStoreDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const isDrawer: boolean = useSelector(
    (state: RootState) => state.stores.isDrawer,
  );
  const dataMenu: any = useSelector(
    (state: RootState) => state.stores.dataMenu,
  );
  const hasFetchedResources = useSelector(
    (state: RootState) => state.stores.hasFetchedResources,
  );
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.sales.containerDealStatuses,
  ) as { id: number; value: string }[];
  const dealType = useSelector<RootState>((state) => state.sales.dealType) as {
    id: number;
    value: string;
  }[];
  const [name, setName] = useState('');

  const [isTerm, setIsTerm] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isCompanys, setIsCompanys] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isTerminalTable, setIsTerminalTable] = useState(false);
  const [isContainers, setIsContainers] = useState(false);
  const [isListTerminals, setIsListTerminals] = useState(false);
  const [isLoad, setLoad] = useState(false);

  const onClick = (e: any, openKeys: string[]): void => {
    switch (e.key) {
      case RouteNames.DEPOT:
        return router.push(RouteNames.DEPOT, { openKeys });
      case RouteNames.CRM:
        return router.push(RouteNames.CRM_COMPANY, { openKeys });
      case RouteNames.CONTAINERS:
        return router.push(RouteNames.CONTAINERS, { openKeys });
      case RouteNames.TERMINALS:
        return router.push(RouteNames.TERMINALS, { openKeys });
      case RouteNames.SALES_DEAL:
        return router.push(RouteNames.SALES_DEAL, { openKeys });
      case RouteNames.RENT_DEAL:
        return router.push(RouteNames.RENT_DEAL, { openKeys });
      case '/finance':
      case '/logistics':
        return;
      default:
        router.push(RouteNames.CRM, { openKeys });
    }
  };

  const exit = () => {
    dispatch(setLang('ru'));
    delay(() => {
      localStorage.removeItem('_in');
      dispatch(setAuth(false));
      router.push(RouteNames.LOGIN);
    }, 0)
  };

  useEffect(() => {
    const loadMenuLocal = async () => {
      setIsCompanys(await Helpers.localGroupEntities('companys'));
      setIsContact(await Helpers.localGroupEntities('contacts'));
      setIsStaff(await Helpers.localGroupEntities('staff'));
      setIsTerm(await Helpers.localGroupEntities('term'));
      setIsContainers(await Helpers.localGroupEntities('containers'));
      setIsTerminalTable(await Helpers.localGroupEntities('terminalTable'));
      setIsListTerminals(await Helpers.localGroupEntities('terminals'));
      setLoad(true);
    };
    if (Utils.getStorage('ent') === null) {
      dispatch(entitiesInfo(Utils.role())).then(async (response: any) => {
        if (Utils.role().id === response.payload.role_id) {
          await Utils.setStorage(
            'ent',
            await Utils.encrypt(JSON.stringify(response.payload.res_client)),
          );
          const resLoc = await Utils.getStorage('ent');
          if (resLoc !== null) {
            loadMenuLocal();
          }
        }
      });
    } else {
      loadMenuLocal();
    }
    socket.once('res-update-group-entities', async (response: any) => {
      if (Utils.role().type === 'group') {
        if (Utils.role().id === response.role_id) {
          await Utils.setStorage(
            'ent',
            await Utils.encrypt(JSON.stringify(response.res_client)),
          );
          const resLoc = await Utils.getStorage('ent');
          if (resLoc !== null) {
            loadMenuLocal();
          }
        }
      }
    });

    socket.once('res-update-entities', async (response: any) => {
      if (Utils.role().type === 'group') {
        const loc = await Utils.decrypt(await localStorage.getItem('ent'));
        loc.map((item: any) => {
          response.res_client.map((el: any) => {
            if (el.entities === item.entities && el.status) {
              item.status = el.status;
            }
          });
        });
        await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(loc)));
        const resLoc = await Utils.getStorage('ent');
        if (resLoc !== null) {
          loadMenuLocal();
        }
      } else {
        await Utils.setStorage(
          'ent',
          await Utils.encrypt(JSON.stringify(response.res_client)),
        );
        const resLoc = await Utils.getStorage('ent');
        if (resLoc !== null) {
          loadMenuLocal();
        }
      }
    });

    const localUser = async () => {
      if (Utils.getStorage('_in') !== null) {
        const user = await Utils.decrypt(Utils.getStorage('_in'));
        setName(
          user.name === null ? 'Гость' : user.name + ' ' + user.last_name,
        );
      }
    };
    localUser();

    if (!hasFetchedResources) {
      dispatch(getResources());
      dispatch(setFetchedResources(true));
    }

    socket.on('res-resources', resResources);
    return () => {
      socket.off('res-resources', resResources);
    };
  }, []);

  const resResources = useCallback((response: any) => {
    dispatch(setTypeCompany(response.arCompany));
    dispatch(setDataCurrency(response.arCurrency));
    dispatch(setTypeContainers(response.arContainers));
    dispatch(setTypeQuality(response.arQuality));
    dispatch(setTypePhone(response.arPhone));
    dispatch(setTypeMessenger(response.arMessenger));
    dispatch(setTypeEmail(response.arEmail));
    dispatch(setSourceContact(response.arSourceContact));
    dispatch(setStatusContainer(response.arStatusContainer));
    dispatch(setTerminalDataCity(response.arTerminalChildCity));
  }, []);

  useEffect(() => {
    if (!containerDealStatuses?.length) {
      dispatch(getContainerDealStatuses());
    }
    if (!dealType?.length) {
      dispatch(getTypeDeal());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(findMyCompany());
    dispatch(getPercent());
  }, []);

  return (
    <Layout className={style.layout}>
      <Sider
        trigger={null}
        collapsible
        collapsed={isDrawer}
        collapsedWidth={60}
        className={'sider'}
        width={252}
      >
        <Flex justify="space-between" align="center" className={'sider avatar'}>
          <Flex
            justify="space-between"
            align="center"
            gap={10}
            style={
              !isDrawer
                ? { marginLeft: 8, marginTop: 8, marginBottom: 13 }
                : {
                    marginTop: 8,
                    marginBottom: 7,
                    width: '100%',
                    justifyContent: 'center',
                  }
            }
          >
            <Avatar
              size={!isDrawer ? 36 : 42}
              src={
                Utils.getUserInfo().avatar !== null &&
                Utils.getUserInfo().avatar
              }
              icon={<UserOutlined />}
            />
            {!isDrawer && <h3 style={{ margin: 0 }}>{name} </h3>}
          </Flex>
          {!isDrawer && (
            <Button
              type="text"
              style={{ marginRight: 10 }}
              icon={isDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => dispatch(setDrawer(true))}
            />
          )}
        </Flex>
        <Flex
          className={style.layout__menu}
        >
          <Badge count={5} size="small">
            <Icon component={notificationIcon} />
          </Badge>
          {!isDrawer && <div className={'text-notification'}>{t("Уведомления")}</div>}
        </Flex>
        {isLoad && (
          <Menus
            onClick={onClick}
            data={dataMenu}
            isContact={isContact}
            isTerm={isTerm}
            isCompanys={isCompanys}
            isStaff={isStaff}
            isTerminalTable={isTerminalTable}
            isContainers={isContainers}
            isListTerminals={isListTerminals}
          />
        )}
        <Divider style={{ marginTop: 6, marginBottom: 6 }} />
        <Flex
          className={classnames (style.layout__menu, {[style.layout__menu_icon]: isDrawer}) }
          justify={'flex-start'}
          align={'center'}
          style={{ cursor: 'pointer' }}
          gap={8}
        >
          <TooltipButton
            propsTooltip={{
              title: !isDrawer ? null : t("Выход"),
              placement: 'right',
            }}
            propsButton={{
              type: 'text',
              icon: (
                <Icon className={style.layout__icon} component={ExitIcon} />
              ),
              iconPosition: 'start',
              className: style.layout__link,
              onClick: exit,
            }}
          >
            {!isDrawer ? t("Выход") : null}
          </TooltipButton>
        </Flex>
      </Sider>
      <Layout>
        <Flex
          justify={'space-between'}
          align={'center'}
          style={{ width: '100%' }}
          gap={8}
        >
          <Headers setCollapsed={setCollapsed} collapsed={collapsed} />
        </Flex>
        <Content className={'content'}>
          <div>{props.children}</div>
        </Content>
        <DrawerRight col={8} />
      </Layout>
    </Layout>
  );
};

export default Layouts;
