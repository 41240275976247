import { delay } from 'lodash';
import { Select, SelectProps, Tooltip } from 'antd';
import React, { useState } from 'react';

interface ISelect extends SelectProps<any> {
  defaultValue?: any;
  api?: (val: string) => Promise<any>;
  mapResponse?: (res: any) => { value: any; label: any }[];
  options?: { value: any; label: any }[];
  style?: React.CSSProperties;
  className?: string;
}

export const FormSelectSearch: React.FC<ISelect> = ({
  api,
  mapResponse,
  options,
  defaultValue,
  style = {},
  className = '',
  ...props
}) => {
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = (text: string) => {
    if (text?.length >= 2 && api && mapResponse) {
      setLoading(true);
      delay(() => api(text)
        .then(mapResponse)
        .then((arr) => {
          if (arr?.length) {
            setSelectOptions(arr.filter((item) => item?.label?.includes(text)));
          } else {
            setSelectOptions([]);
          }
        }).finally(() => {
          setLoading(false);
        }), 1000);
    }
  };

  return (
    <Select
      {...props}
      className={className}
      style={style}
      loading={loading}
      defaultValue={defaultValue || null}
      onSearch={handleSearch}
      notFoundContent={null}
      options={options || loading ? [] : selectOptions}
      labelRender={(opt) => {
        return (
          <Tooltip title={opt.value}>
            <span>{opt.value}</span>
          </Tooltip>
        );
      }}
    />
  );
};

export default FormSelectSearch;
