import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col, DatePicker,
    Divider,
    Dropdown,
    Flex, Form, Input,
    message,
    Modal, notification,
    Row,
    Select, Tooltip,
    Typography
} from "antd";
import Icon, {CheckOutlined, CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {blackCloseIcon, copyIcon, EditIcon, errorImportIcon, SaveIcon} from "../../assets/image/svg";
import {useTranslation} from "react-i18next";
import {columns as crmColumns} from "./columns";
import {fileColumns, TerminalService} from "./service";
import {useStoreDispatch} from "../../store";
import {findTerminalChildCity, setTerminal} from "../../store/terminals";
import {SelectSearch} from "../../components/ui/SelectSearch/SelectSearch";
import FormModalTerminal from "../terminals/formModalTerminal";
import {Utils} from "../../utils";
import {setContainerImport, validCompanyContainer} from "../../store/containers";

const {Title, Text} = Typography;
const {Option} = Select;

interface FormModalImportProps {
    closeModalImport?: any,
    loadings?: boolean,
    setLoadings: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const FormModalImport = ({closeModalImport, loadings, setLoadings}: FormModalImportProps) => {
    const {t} = useTranslation();
    const dispatch = useStoreDispatch();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [mapping, setMapping] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [errorsNum, setErrorsNum] = useState<number>(0);
    const [isAddTerminal, setIsAddTerminal] = useState<boolean>(false);
    const [isOpenModalTerminal, setIsOpenModalTerminal] = useState<boolean>(false);
    const [terminals, setTerminals] = useState<any[]>([]);
    const [fileNameTerminal, setFileNameTerminal] = useState<string | undefined | number>("");
    const [isOpenModalErrorCompany, setIsOpenModalErrorCompany] = useState<boolean>(false);
    const [infoDataError, setInfoDataError] = useState<any[]>([]);
    const [disabledSave, setDisabledSave] = useState<boolean>(true);
    const [clickedIcons, setClickedIcons] = useState<boolean[]>([]);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isMuted, setIsMuted] = useState<boolean>(true);
    const [editNumContainer, setEditNumContainer] = useState<boolean>(false);
    const [editTerminal, setEditTerminal] = useState<boolean>(false);
    const [editCompany, setEditCompany] = useState<boolean>(false);
    const [editDate, setEditDate] = useState<boolean>(false);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [editingIndexTerminal, setEditingIndexTerminal] = useState<number>(-1);
    const [editingIndexCompany, setEditingIndexCompany] = useState<number>(-1);
    const [editingIndexDate, setEditingIndexDate] = useState<number>(-1);
    const [editedContainers, setEditedContainers] = useState<any>({});
    const [editedTerminal, setEditedTerminal] = useState<any>({});
    const [editedCompany, setEditedCompany] = useState<any>({});
    const [editedDate, setEditedDate] = useState<any>({});
    const [resDate, setResDate] = useState<any>({ data: [], itemIndex: 0 });
    const [resNumContainer, setResNumContainer] = useState<any>({ data: [], itemIndex: 0 });
    const [resTerminal, setResTerminal] = useState<any>({ data: [], itemIndex: 0 });
    const [resCompany, setResCompany] = useState<any>({ data: [], itemIndex: 0 });
    const [itemsErrors, setItemsErrors] = useState<any>([]);
    const notificationKey = 'unique-notification-key';

    const initial = () => {
        return new Promise((resolve, reject) => {
            resolve(TerminalService.initialMapping());
        });
    }

    useEffect(() => {
        setDisabledSave(true)
        initial().then((initialMapping: any) => {
            setErrorsNum(initialMapping.filter((item: any) =>
                !item.disabled && item.crmColumn.title !== 'Фото' && item.crmColumn.title !== '').length);
            const errorsName = initialMapping.reduce((acc: any, item: any) => {
                if (!item.disabled && item.crmColumn.title !== 'Фото' && item.crmColumn.title !== '') {
                    acc.push({
                        key: item.crmColumn.key,
                        label: item.crmColumn.title
                    });
                }
                return acc;
            }, []);
            setItemsErrors(errorsName);
            setMapping(initialMapping);
            initialMapping.map((item: any) => {
                if (item.crmColumn.title === 'Терминал') {
                    const indexTerminal = fileColumns[0].indexOf(item.fileColumn);
                    setFileNameTerminal(fileColumns[1][indexTerminal]);
                }
            });
        });

    }, [crmColumns, fileColumns[0]]);

    const handleCopy = async (row: string, index: number, correctedValues: any[], type: string) => {
        // Копируем текст в буфер обмена
        await navigator.clipboard.writeText(row);
        // Устанавливаем флаг clickedIcons для текущего элемента
        const newClickedIcons = [...clickedIcons];
        newClickedIcons[index] = true;
        setClickedIcons(newClickedIcons);

        // Обновляем уведомление с новыми данными
        if (type === 'container') {
            updateNotification(correctedValues, newClickedIcons, 'Ошибка в номере контейнера');
        } else {
            updateNotification(correctedValues, newClickedIcons, 'Компании');
        }
    };

    const updateNotification = (correctedValues: any[], clickedIcons: boolean[], title: string) => {
        setClickedIcons([])
        notification.open({
            key: notificationKey,
            message: title,
            description: (
                <div>
                    <ul>
                        {correctedValues.map((row, index) => (
                            index < 10 &&
                            <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                                <li>{row}</li>
                                {clickedIcons[index] ? (
                                    <CheckOutlined style={{color: 'green'}}/>
                                ) : (
                                    <Icon component={copyIcon} onClick={() =>
                                        handleCopy(row, index, correctedValues, title !== 'Компании' ? 'container' : '')}/>
                                )}
                            </div>
                        ))}
                    </ul>
                </div>
            ),
            duration: null,
        });
    };

    const handleFileColumnChange = async (value: string, index: number) => {
        // Создание копии mapping и обновление значения fileColumn
        const newMapping: any = [...mapping];
        newMapping[index].fileColumn = value;

        if (index > 0) {
            if (value === 'Пустое поле') {
                newMapping[index].disabled = true;
                setErrorsNum(prev => prev - 1);
                setItemsErrors(itemsErrors.filter((item: any) => +item.key !== index+1));
            }else {
                 setErrorsNum(prev => prev - 1);
                setItemsErrors(itemsErrors.filter((item: any) => +item.key !== index+1));
            }
        }else {
            if (value !== 'Пустое поле') {
                setErrorsNum(prev => prev - 1);
                setItemsErrors(itemsErrors.filter((item: any) => +item.key !== index+1));
            }
        }

        // Валидация и обновление состояния mapping
        await validateMapping(newMapping).then((response: any) => {
            if (newMapping[index].fileColumn !== 'Пустое поле') {
                newMapping[index].disabled = response.length <= 0;
                setMapping(newMapping);
            } else {
                setMapping(newMapping);
            }
        });

        // Подсчет и проверка количества элементов с флагом disabled
        const disabledCount = newMapping.filter((item: any) => item.disabled).length;

        // Снятие блокировки кнопки сохранения при условии
        if (mapping.length - 2 === disabledCount) {
            setDisabledSave(false);
        } else {
            setDisabledSave(true);
        }
    };

    const validateMapping = async (newMapping: any[]) => {
        return new Promise(async (resolve, reject) => {
            const errors = newMapping.reduce((acc: any[], current: any) => {
                if (current.fileColumn !== 'Пустое поле' && current.fileColumn && newMapping.filter((item: any) =>
                    item.fileColumn === current.fileColumn && item !== current).length > 0) {
                    acc.push({
                        crmColumn: current.crmColumn.title
                    });
                }
                resolve(acc)
                return acc;
            }, []);
            setErrors(errors);
        })
    };

    const cancelDisabled = (value: string, index: number) => {
        const newMapping = [...mapping];
        newMapping[index].fileColumn = value;
        newMapping[index].disabled = false;
        setMapping(newMapping);
        validateMapping(newMapping);
    }

    const handleSave = async () => {
        const arResult = [];
        //Обновляем дату в fileColumns
        if (resDate.length > 0) {
            resDate.forEach((itemDate: any) => {
                if (itemDate && itemDate.data && itemDate.itemIndex >= 0) {
                    const data = itemDate.data.find((item: any) => item.index >= 0);
                    if (data) {
                        if (fileColumns[itemDate.itemIndex] && fileColumns[itemDate.itemIndex].length > data.index) {
                            fileColumns[itemDate.itemIndex][data.index] = data.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            })
        }

         //Обновляем номера контейнеров в fileColumns
        if (resNumContainer.length > 0) {
            resNumContainer.forEach((itemNum: any) => {
                if (itemNum && itemNum.data && itemNum.itemIndex >= 0) {
                    const dataNum = itemNum.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (fileColumns[itemNum.itemIndex] && fileColumns[itemNum.itemIndex].length > dataNum.index) {
                            fileColumns[itemNum.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            })
        }

        //Обновляем терминалы в fileColumns
        if (resTerminal.length > 0) {
            resTerminal.forEach((itemTer: any) => {
                if (itemTer && itemTer.data && itemTer.itemIndex >= 0) {
                    const dataNum = itemTer.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (fileColumns[itemTer.itemIndex] && fileColumns[itemTer.itemIndex].length > dataNum.index) {
                            fileColumns[itemTer.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            })
        }

        //Обновляем компании в fileColumns
        if (resCompany.length > 0) {
            resCompany.forEach((itemComp: any) => {
                if (itemComp && itemComp.data && itemComp.itemIndex >= 0) {
                    const dataComp = itemComp.data.find((item: any) => item.index >= 0);
                    if (dataComp) {
                        if (fileColumns[itemComp.itemIndex] && fileColumns[itemComp.itemIndex].length > dataComp.index) {
                            fileColumns[itemComp.itemIndex][dataComp.index] = dataComp.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            })
        }

        // Проверяем терминалы
        try {
            const resTerminals: any = await TerminalService.validDataImportTerminal(mapping);
            const terminalResponse = await dispatch(findTerminalChildCity(resTerminals));
            if (terminalResponse.payload.length > 0) {
                arResult.push({terminal: terminalResponse.payload});
            }
        } catch (error) {
            console.error('Error processing terminals:', error);
        }

        // Проверяем номера контейнеров на валидность
        try {
            const resValidNumContainers: any = await TerminalService.validDataImportNumContainers(mapping);
            if (resValidNumContainers.length > 0) {
                arResult.push({valid_num: resValidNumContainers});
            }
        } catch (error) {
            console.error('Error validating container numbers:', error);
        }

        // Проверяем компании
        try {
            const resValidCompany: any = await TerminalService.validDataImportCompany(mapping);
            const companyResponse = await dispatch(validCompanyContainer(resValidCompany));
            if (companyResponse.payload.length > 0) {
                arResult.push({company: companyResponse.payload});
            }
        } catch (error) {
            console.error('Error processing companies:', error);
        }

        // Проверяем дату прибытия
        try {
            const isValidDate: any = await TerminalService.validDataImportArrivalDate(mapping);
            if (isValidDate.length > 0) {
                arResult.push({date: isValidDate});
            }
        } catch (error) {
            console.error('Error processing date:', error);
        }

        if (arResult.length > 0) {
            setInfoDataError(arResult);
            setIsOpenModalErrorCompany(true);

        }else {
            setLoadings(true);
            TerminalService.onSave(mapping, fileNameTerminal).then(res => {
                dispatch(setContainerImport(res)).then(response =>{
                    setLoadings(false);
                    closeModalImport(false);
                    setMapping([]);
                })
            })
        }
    };

    const handleAddTerminal = () => {
        setIsOpenModalTerminal(true)
    }

    const closeCancelViewModal = () => {
        setIsOpenModalTerminal(false)
        form.resetFields()
    }

    const onFinishAddTerminal = (payload: any, open: boolean) => {
        Object.assign(payload, {type_form: 'create'});
        dispatch(setTerminal(payload)).then((response: any) => {
            if (response.payload.success === 0) {
                Utils.InfoOpenNotification('topRight', response.payload.message, 20, api);
            } else {
                setIsOpenModalTerminal(false);
                setIsAddTerminal(false)
                Utils.openNotification('topLeft', 'Добавлено', api);
            }
        })
    }

    const selectTerminal = (e: any, option: any) => {
        if (typeof option.children === 'object') {
            setFileNameTerminal(JSON.parse(option.value).child)
        }
        const newMapping = mapping.map((elem) => {
            if (elem.crmColumn.title === "Терминал") {
                elem.disabled = true
            }
            return elem;
        });
        setMapping(newMapping)
    }

    const handleClick = () => {
        if (videoRef.current && isMuted) {
            setIsMuted(false);
            videoRef.current.muted = false;
            videoRef.current.play();
        }
    };

    const handleEditNumContainer = (index: number) => {
        setEditNumContainer(true);
        setEditingIndex(index);
    }

    const handleEditTerminal = (index: number) => {
        setEditTerminal(true);
        setEditingIndexTerminal(index);
    }

    const handleEditCompany = (index: number) => {
        setEditCompany(true);
        setEditingIndexCompany(index);
    }

    const handleEditDate = (index: number) => {
        setEditDate(true);
        setEditingIndexDate(index);
    }

    const saveEditNumContainer = (index: number, itemIndex: number, num: string) => {
        setResNumContainer({ data: [], itemIndex: 0 });
        const isValid: boolean = Utils.isValidContainerNumber(editedContainers[0]);
        if (isValid) {
            if (editingIndex === index) {
                const result  = fileColumns[itemIndex].map((el: string,ind: number) => {
                    if (el === num) {
                        setEditNumContainer(false);
                        return { item: editedContainers[index], index: ind };
                    }
                }).filter((row: any) => row !== undefined);
                setResNumContainer((prev: any) => {
                    if (Array.isArray(prev)) {
                        return [{ data: result, itemIndex: itemIndex, index: result[0].index }, ...prev];
                    } else {
                        return [{ data: result, itemIndex: itemIndex, index: result[0].index }];
                    }
                });
            }
        } else {
            message.error('Некорректный номер контейнера')
        }
    }

    const saveEditTerminal = (index: number, itemIndex: number, item: any) => {
        setResTerminal({ data: [], itemIndex: 0 });
        if (editingIndexTerminal === index) {
            const result = fileColumns[itemIndex].map((el: string, ind: number) => {
                if (el === item.name) {
                    setEditTerminal(false);
                    return {item: editedTerminal[index], index: ind};
                }
            }).filter((row: any) => row !== undefined);
            setResTerminal((prev: any) => {
                if (Array.isArray(prev)) {
                    return [{data: result, itemIndex: itemIndex, index: result[0].index}, ...prev];
                } else {
                    return [{data: result, itemIndex: itemIndex, index: result[0].index}];
                }
            });
        }
    }

    const saveEditCompany = (index: number, itemIndex: number, item: any) => {
        setResCompany({ data: [], itemIndex: 0 });
        if (editingIndexCompany === index) {
            const result  = fileColumns[itemIndex].map((el: string,ind: number) => {
                if (el === item.name) {
                    setEditCompany(false);
                    return {item: editedCompany[index], index: ind};
                }
            }).filter((row: any) => row !== undefined);
            setResCompany((prev: any) => {
                if (Array.isArray(prev)) {
                    return [{data: result, itemIndex: itemIndex, index: result[0].index}, ...prev];
                } else {
                    return [{data: result, itemIndex: itemIndex, index: result[0].index}];
                }
            });
        }
    }

    const saveEditDate = (index: number, itemIndex: number, item: any) => {
        setResDate({ data: [], itemIndex: 0 });
        if (editingIndexDate === index) {
            const result = fileColumns[itemIndex].map((el: string, ind: number) => {
                if (el === editedDate || el === item.date) {
                    setEditDate(false);
                    return { item: editedDate[index], index: ind };
                }
            }).filter((row: any) => row !== undefined);
            setResDate((prev: any) => {
                if (Array.isArray(prev)) {
                    return [{ data: result, itemIndex: itemIndex, index: result[0].index }, ...prev];
                } else {
                    return [{ data: result, itemIndex: itemIndex, index: result[0].index }];
                }
            });
        }
    };

    const handleCloseModalImport = () => {
        setIsOpenModalErrorCompany(false);
    }

    return (
        <>
            {contextHolder}
            <Flex justify={'space-between'} align={"center"}>
                <Flex className={'box-import'} align={'flex-start'} justify={'space-between'}
                      style={{width: '100%'}}>
                    <Flex align={'center'} justify={'center'} gap={24}>
                        <div className={'box-icon-import'}>
                            <Icon component={errorImportIcon}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                            <div className='label-card-20'>{t('importHeaderTitle')}</div>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={12} lg={15}>
                                    <div className='text-card-12 box-text-import'>{t('importHeaderText')}</div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={7}>
                                    <Flex vertical gap={10}>
                                        <Dropdown menu={{items: itemsErrors}} arrow>
                                            <Button
                                                style={{
                                                    color: 'black',
                                                    borderColor: errorsNum > 0 ? 'red' : 'green'
                                                }}
                                            >
                                                {'Количество ошибок: ' + errorsNum}
                                            </Button>
                                        </Dropdown>
                                        {isAddTerminal &&
                                            <Flex vertical>
                                                <p className={'text-card-12'}
                                                   style={{marginBottom: 0}}>{t('chooseTerminal')}</p>
                                                <SelectSearch
                                                    onChangeSelect={selectTerminal}
                                                    dataSource={terminals}
                                                    hiddenBtn
                                                    type={'page'}
                                                    onClick={handleAddTerminal}
                                                />
                                            </Flex>
                                        }
                                    </Flex>
                                </Col>
                            </Row>
                        </div>
                    </Flex>
                    <Icon component={blackCloseIcon} onClick={closeModalImport}/>
                </Flex>
            </Flex>
            <Divider style={{marginTop: 14, marginBottom: 26}}/>
            <div className={'box-import'}>
                <Row gutter={[50, 50]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Flex gap={5}>
                            <Col span={12}>
                                <Title level={5}>{t('columnNameCRM')}</Title>
                                <Flex gap={8} vertical>
                                    {mapping?.map((item: any, index: number) => (
                                        item.crmColumn.title !== '' && item.crmColumn.title !== 'Фото' &&
                                        <Row key={index}>
                                            <Col span={2}>{index + 1}</Col>
                                            <Col span={20}>
                                                <div className={
                                                    !item.disabled ? 'column-name-crm' : 'column-name-crm disabled'
                                                }>{item.crmColumn.title}</div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>Имя столбца в файле:</Title>
                                <Flex gap={8} vertical>
                                    {mapping.map((item: any, index: number) => (
                                        item.crmColumn.title !== '' && item.crmColumn.title !== 'Фото' &&
                                        <Flex key={index} gap={5}>
                                            <Select
                                                placeholder={'Выберите имя'}
                                                disabled={item.disabled}
                                                style={item.disabled ? {width: '100%'} : {width: '79%'}}
                                                value={item.fileColumn}
                                                onChange={(value) => handleFileColumnChange(value, index)}
                                            >
                                                {fileColumns[0].map((fileColumn: string) => (
                                                    <Option key={fileColumn} value={fileColumn}>
                                                        {fileColumn}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Flex>
                                                {errors.find((error: any) => error.crmColumn === item.crmColumn.title) ? (
                                                    <Tooltip title="Изменить"
                                                             arrow={false}
                                                             color={'#fff'}
                                                             overlayClassName="custom-tooltip"
                                                             placement="topLeft">
                                                        <Flex gap={5} align={"center"}>
                                                            <Button icon={<CloseOutlined
                                                                style={{fontSize: '12px', color: 'red'}}/>}
                                                                    onClick={() => cancelDisabled(item, index)}/>
                                                            <span>Дубликат</span>
                                                        </Flex>
                                                    </Tooltip>
                                                ) : (
                                                    <Flex gap={8} align={"center"}>
                                                        {item.disabled &&
                                                            <Tooltip title="Изменить"
                                                                     arrow={false}
                                                                     color={'#fff'}
                                                                     overlayClassName="custom-tooltip"
                                                                     placement="topLeft">
                                                                <Flex gap={5} align={"center"}>
                                                                    <Button icon={<CloseOutlined
                                                                        style={{fontSize: '12px', color: 'red'}}/>}
                                                                            onClick={() => cancelDisabled(item, index)}/>
                                                                </Flex>
                                                            </Tooltip>
                                                        }
                                                        {item.disabled ?
                                                            <CheckOutlined style={{color: 'green'}}/>
                                                            :
                                                            <CheckOutlined style={{color: 'gray'}}/>
                                                        }
                                                    </Flex>
                                                )}
                                            </Flex>

                                        </Flex>
                                    ))}
                                </Flex>
                            </Col>
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <video
                            onClick={handleClick}
                            width="90%"
                            ref={videoRef}
                            autoPlay
                            muted={isMuted}
                            loop
                            controls>
                            <source
                                src="/videos.mp4"
                                type="video/mp4"/>
                            Your browser doesn't support HTML5 video tag.
                        </video>

                    </Col>
                    <Col span={24} offset={6}>
                        <Button disabled={disabledSave} type={'primary'} onClick={handleSave} loading={loadings}>
                            Сохранить изменения и загрузить
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title={'Добавить терминал'}
                maskClosable={false}
                footer={null}
                onCancel={closeCancelViewModal}
                width={548}
                open={isOpenModalTerminal}>
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    <FormModalTerminal
                        nameTerminal={fileNameTerminal}
                        form={form}
                        typeForm='create'
                        onFinishAddTerminal={onFinishAddTerminal}
                        dataCityRedux={terminals}
                    />
                </Flex>
            </Modal>
            <Modal
                closable={false}
                footer={null}
                width={'94%'}
                open={isOpenModalErrorCompany}>
                <Flex className={'modal-content'} vertical>
                    <Flex justify={"space-between"} style={{width: '100%'}}>
                        <Flex align={'center'} justify={'start'} gap={24} style={{width: '100%'}}>
                            <div className={'box-icon-import'}>
                                <Icon component={errorImportIcon}/>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                <Flex justify={"space-between"} style={{width: '100%'}}>
                                    <div className='label-card-20'>Исправьте ошибки</div>
                                </Flex>
                                <Row gutter={20}>
                                    <Col xs={24} sm={12} md={12} lg={15}>
                                        <div className='text-card-12 box-text-import'>
                                            Пожалуйста, исправьте ошибки перечисленные ниже
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Flex>
                        <Flex align={'flex-start'}>
                            <Icon component={blackCloseIcon} onClick={handleCloseModalImport}/>
                        </Flex>
                    </Flex>
                </Flex>
                <Divider style={{marginTop: 10}}/>
                <Flex justify={"space-between"} className={'modal-content'} gap={10}>
                    {infoDataError.map((item, index) => (
                        <div key={index}>
                            {item?.terminal?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 &&
                                        <div className={'label-card-16'}>Этих терминалов нет в базе:</div>
                                    }
                                    <Flex justify={"space-between"} gap={5}>
                                        {
                                            editingIndexTerminal === index && editTerminal ?
                                                <Input defaultValue={editedTerminal[index] || item.name}
                                                       size="small" style={{width: 130}}
                                                       onChange={(e: any) => {
                                                           setEditedTerminal({
                                                               ...editedTerminal,
                                                               [index]: e.target.value
                                                           });
                                                       }}/>
                                                :editedTerminal[index] || item.name
                                        }
                                        <Flex gap={5}>
                                            <Tooltip title="Добавить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<PlusOutlined/>} size="small"/>
                                            </Tooltip>
                                            <Tooltip title="Сохранить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<SaveIcon/>} size="small"
                                            onClick={() =>saveEditTerminal(index, item.index, item)}/>
                                            </Tooltip>
                                            <Tooltip title="Изменить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<EditIcon/>} size="small"
                                            onClick={ () => handleEditTerminal(index)}/>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.valid_num?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 &&
                                        <div className={'label-card-16'}>Номера контейнера некорректны:</div>
                                    }
                                    <Flex justify="space-between" gap={10} align="center">
                                        {
                                            editingIndex === index && editNumContainer ?
                                                <Input defaultValue={editedContainers[index] || item.num}
                                                       size="small" style={{width: 130}}
                                                       onChange={(e: any) => {
                                                           setEditedContainers({
                                                               ...editedContainers,
                                                               [index]: e.target.value
                                                           });
                                                       }}/>
                                                : editedContainers[index] || item.num
                                        }
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<SaveIcon/>} size="small"
                                                    onClick={() => saveEditNumContainer(index, item.index, item.num)}/>
                                            </Tooltip>
                                            <Tooltip title="Изменить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<EditIcon/>} size="small"
                                                    onClick={() => handleEditNumContainer(index)}/>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>

                            ))}
                            {item?.company?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 &&
                                        <div className={'label-card-16'}>Этих компаний нет в базе:</div>
                                    }
                                    <Flex justify={"space-between"} gap={5}>
                                        {
                                            editingIndexCompany === index && editCompany ?
                                                <Input defaultValue={editedCompany[index] || item.name}
                                                       size="small" style={{width: 130}}
                                                       onChange={(e: any) => {
                                                           setEditedCompany({
                                                               ...editedCompany,
                                                               [index]: e.target.value
                                                           });
                                                       }}/>
                                                :editedCompany[index] || item.name
                                        }
                                        <Flex gap={5}>
                                            <Tooltip title="Добавить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<PlusOutlined/>} size="small"/>
                                            </Tooltip>
                                            <Tooltip title="Сохранить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<SaveIcon/>} size="small"
                                                    onClick={() =>saveEditCompany(index, item.index, item)}/>
                                            </Tooltip>
                                            <Tooltip title="Изменить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<EditIcon/>} size="small"
                                                    onClick={ () => handleEditCompany(index)}/>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.date?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 &&
                                        <div className={'label-card-16'}>Дата некорректна:</div>
                                    }
                                    <Flex gap={10} align="center">
                                        {editingIndexDate === index && editDate ?
                                            <Flex vertical>
                                                <Text>{item.num}</Text>
                                                <Text>{item.date}</Text>
                                                <DatePicker
                                                    format="DD.MM.YYYY"
                                                    size="small"
                                                    style={{width: 130}}
                                                    onChange={(e: any, dateString: any) => {
                                                        setEditedDate({
                                                            ...editedDate,
                                                            [index]: dateString
                                                        });
                                                    }}/>
                                            </Flex>
                                            : editedDate[index] || item.date
                                        }
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<SaveIcon/>} size="small"
                                                    onClick={() => saveEditDate(index, item.index,item)}/>
                                            </Tooltip>
                                            <Tooltip title="Изменить"
                                                     arrow={false}
                                                     color={'#fff'}
                                                     overlayClassName="custom-tooltip"
                                                     placement="topLeft">
                                            <Button icon={<EditIcon/>} size="small"
                                                    onClick={ () => handleEditDate(index)}/>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </div>
                    ))
                    }
                </Flex>
            </Modal>
        </>
    )
};

export default FormModalImport;
