import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { DatePicker } from 'antd';


interface IDate {
  format?: string;
  id?: string;
  value?: string | null;
  onChange?: (...props: any) => void;
  handlerChange: (str: string | null) => void
}
const FormDatePicker: React.FC<IDate> = ({id, value, handlerChange, onChange, format = 'DD.MM.YYYY'}) => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    setData(value ? dayjs(value, format)?.isValid() ? dayjs(value, format) : null : null);
  }, [value])

  return (
    <DatePicker
      id={id}
      value={data}
      format={format}
      onChange={(date, str) => {
        if (onChange) {
          onChange(str);
        }
        setData(date);
        handlerChange(str as string);
      }}
      style={{ width: '100%' }}
    />
  );
};

export default FormDatePicker
