import React, { ReactElement, useEffect, useState } from 'react';

import axios from 'axios';
import { GetProp, message, Upload, UploadProps } from 'antd';

import { delay } from 'lodash';
import { UploadFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { messagesModal } from '../../../constants';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import UploaderDocumentItem from './UploaderDocsItem/UploaderDocsItem';
import {
  checkFileTypes,
  downloadContent,
} from '../../../helpers/input-helpers';
import style from './UploaderDocs.module.scss';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const URL: any = process.env.REACT_APP_API_URL;

interface IUploaderDocs {
  ids?: any[];
  type?: string;
  setDataIds?: (value: number[]) => void;
  onChange?: (value: any) => void;
  accept?: string;
  name?: string;
  value?: any[];
  urlUpload?: string;
  urlDownLoad?: string;
  urlDelete?: string;
  handlerSaveFiles?: (files: any[]) => void;
  isView?: boolean;
  isOpen?: boolean;
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const UploaderDocs: React.FC<IUploaderDocs> = ({
  name,
  ids,
  type,
  setDataIds,
  onChange,
  handlerSaveFiles,
  accept = '*',
  urlUpload = '/uploadFiles',
  urlDownLoad = '/downloadFiles',
  urlDelete = '/deleteFiles',
  isView = false,
  isOpen = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [beforeFileList, setBeforeFileList] = useState<any[]>([]);
  const [fileListIds, setFileListIds] = useState<{ id: number; uid: string }[]>(
    [],
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [deleteFile, setDeleteFile] = useState<UploadFile | null>(null);
  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      if (!checkFileTypes(accept, file?.type ?? '')) {
        throw new Error('ошибка типа файла');
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = (await reader.result) as Buffer;
        axios
          .post(
            `${URL}${urlUpload}`,
            { file: base64, file_pdf: '', name: file.name, type: file.type },
            config,
          )
          .then((res) => {
            if (setDataIds) {
              setDataIds([...fileListIds.map(({ id }) => id), res.data]);
            }
            setFileListIds((prev) => [
              ...prev,
              { id: res.data, uid: file.uid },
            ]);
            onSuccess(file);
            message.success('Файл сохранен');
          })
          .catch((error) => {
            onError(error);
          });
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const dummyRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    try {
      if (!checkFileTypes(accept, file?.type ?? '')) {
        throw new Error('ошибка типа файла');
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = (await reader.result) as Buffer;
        setBeforeFileList((prev) => {
          const result = [{ file: base64, file_pdf: '', name: file.name, type: file.type }, ...prev];
          return result;
        });
        delay(() => {
          onSuccess('ok');
        }, 1000);
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);
    if (handlerSaveFiles) {
      handlerSaveFiles(beforeFileList);
    }

    if (file.status === 'uploading') {
      setLoading(true);
      if (!checkFileTypes(accept, file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        setLoading(false);
        return;
      }
      if (fileList.find(({ uid }) => uid === file.uid)) {
        message.error('Такой файл уже есть');
        setLoading(false);
        return;
      }
    }

    const result = newFileList.filter((file) => !file.error);

    if (file.status === 'done' || file.error) {
      setLoading(false);
      setFileList(result);
    }
  };

  const handlePreview: UploadProps['onPreview'] = async (file: any) => {
    if (!file?.url) {
      getBase64(file.originFileObj as FileType, (url) => {
        downloadContent({
          content: '',
          type: file?.type,
          fileName: file?.name,
          url,
        });
      });
      return;
    }

    downloadContent({
      content: '',
      type: file?.type,
      fileName: file?.name,
      url: file.url,
    });
  };

  const openConfirmDeleteFile = (file: UploadFile) => {
    setDeleteFile(file);
    setIsConfirm(true);
  };

  const handleRemove = () => {
    const filesIds = fileListIds.filter(({ uid }) => uid !== deleteFile?.uid);
    const resultId = filesIds.map(({ id }) => id);
    setFileListIds((prev) => prev.filter(({ uid }) => uid !== deleteFile?.uid));
    if (onChange) {
      onChange(resultId);
    }
    if (setDataIds) {
      setDataIds(resultId);
    }

    setFileList((prev) => prev.filter(({ uid }) => uid !== deleteFile?.uid));
    setDeleteFile(null);
  };

  useEffect(() => {
    setFileList([]);
    if (ids?.length) {
      const fetchFiles = async () => {
        const getArrayFiles = (data: UploadFile[]) =>
          data.map((item: any) => ({
            id: item.id,
            uid: item.id,
            name: item.name,
            type: item.type,
            status: 'done' as UploadFileStatus,
            url: item.file,
          }));
        try {
          const { data } = await axios.post(
            `${URL}${urlDownLoad}`,
            ids,
            config,
          );
          const currentIds = data.map((file: any) => ({
            id: file.id,
            uid: file.id,
          }));
          const listIds = currentIds.map((file: any) => file.id);
          setFileList(getArrayFiles(data));
          setFileListIds(currentIds);
          if (setDataIds) {
            setDataIds(listIds);
          }
        } catch (error) {
          message.error('Не удалось загрузить файл');
        }
      };
      fetchFiles();
    }
  }, []);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );

  return (
    <>
      <Upload
        className={style['container']}
        name={name}
        accept={accept}
        customRequest={handlerSaveFiles ? dummyRequest : handleUpload}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        itemRender={(
          originNode: ReactElement,
          file: UploadFile,
          fileList: any[],
          actions: {
            download: (file: UploadFile) => void;
            preview: any;
            remove: any;
          },
        ) => (
          <UploaderDocumentItem
            isView={isView}
            file={file}
            handlePreview={handlePreview}
            openConfirmDeleteFile={openConfirmDeleteFile}
          />
        )}
      >
        {isView ? null : uploadButton}
      </Upload>
      <ModalConfirm
        title={messagesModal.delete.title}
        subtitle={messagesModal.delete.subtitle}
        isOpen={isConfirm}
        closeModal={() => setIsConfirm(false)}
        actionAfterConsent={handleRemove}
      />
    </>
  );
};
