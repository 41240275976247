import React from 'react';
import { Space, InputNumber, InputNumberProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import style from './style.module.scss';

interface IInput extends InputNumberProps {
  value: any;
  name?: string;
  placeholder?: string;
  min?: number;
  maxLength?: number;
  className?: string;
  onChange?: (val: any) => void;
  [key: string]: any;
}

const FormInputNumber: React.FC<IInput> = ({
  value,
  onChange,
  name = '',
  className = '',
  min = 0,
  maxLength = 15,
  controls = false,
  placeholder = 'Введите число',
  ...props
}) => {
  return (
    <Space>
      <InputNumber<any>
        {...props}
        className={classnames(style.input, { [className]: className })}
        value={value}
        controls={controls}
        placeholder={placeholder}
        min={min}
        maxLength={maxLength}
        onChange={(val) => {
          if (onChange) {
            onChange(val);
          }
        }}
      />
      {value ? (
        <CloseCircleOutlined
          className={style.input__icon}
          onClick={() => {
            if (onChange) {
              onChange(null);
            }
          }}
        />
      ) : null}
    </Space>
  );
};

export default FormInputNumber;
