// для accept Uploader
export const fileTypes = {
  doc: '.doc,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  xls: '.xlsx,.xls,application/excel,application/vnd.msexcel,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: '.csv,text/csv,application/csv,text/comma-separated-values',
  pdf: '.pdf,application/pdf',
  image: 'image/*',
  jpeg: 'image/jpeg',
};
export const checkFileTypes = (keys: string, key: string) => {
  if (key && keys) {
    return keys.split(',').includes(key);
  }
  return false;
};

export const getFileNameAndType = (name: string) => {
  if (!name) return {type: '', title: ''};
  const [title, ...arrName] = name?.split('.');
  return {title: title ?? '', type: arrName?.length ? arrName.at(-1) ?? '' : ''};
}

function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function downloadContent({
  content,
  url,
  fileName = 'text.txt',
  type = 'text/plain',
  isOpen = false,
}: {content: string, fileName?: string, type?: string, url?: string, isOpen?: boolean}) {
  const link = document.createElement('a');
  const file = new Blob([content], { type });
  link.href = url || URL.createObjectURL(file);
  link.download = fileName;
  link.click();
}