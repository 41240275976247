import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ITerminal} from "../types/terminal";
import {Rest_terminal} from "../services/rest_terminal";

interface ITerminalState {
    terminalData: Array<ITerminal>,
    dataCity: [],
    dataChildCity: []
}

const initialState: ITerminalState = {
    terminalData: [],
    dataCity: [],
    dataChildCity: []

};
export const setTerminal = createAsyncThunk(
    'setTerminal',
    async (payload: {}) => {
        return await Rest_terminal.setTerminal(payload)
    }
)

export const refTerminal = createAsyncThunk(
    'refTerminal',
    async (payload: {}) => {
        return await Rest_terminal.refTerminal(payload)
    }
)
export const getTerminal = createAsyncThunk(
    'getTerminal',
    async (payload: {}) => {
        return await Rest_terminal.getTerminal(payload)
    }
)
export const findTerminalOne = createAsyncThunk(
    'findTerminalOne',
    async (id: string) => {
        return await Rest_terminal.findTerminalOne(id)
    }
)
export const searchTerminalCity = createAsyncThunk(
    'searchTerminalCity',
    async (payload: string) => {
        return await Rest_terminal.searchTerminalCity(payload)
    }
)
export const getTerminalCity = createAsyncThunk(
    'getTerminalCity',
    async () => {
        return await Rest_terminal.getTerminalCity()
    }
)
export const findTerminalCity = createAsyncThunk(
    'findTerminalCity',
    async (payload: string) => {
        return await Rest_terminal.findTerminalCity(payload)
    }
)
export const findTerminalChildCity = createAsyncThunk(
    'findTerminalChildCity',
    async (payload: any) => {
        return await Rest_terminal.findTerminalChildCity(payload)
    }
)
export const setTerminalCity = createAsyncThunk(
    'setTerminalCity',
    async (payload: {}) => {
        return await Rest_terminal.setTerminalCity(payload)
    }
)
export const deleteTerminal = createAsyncThunk(
    'deleteTerminal',
    async (payload: {}) => {
        return await Rest_terminal.deleteTerminal(payload)
    }
)

export const getFiltersTerminals = createAsyncThunk(
    'getFiltersTerminals',
    async (payload: {}) => {
        return await Rest_terminal.getFiltersTerminals(payload)
    }
)
const terminalSlice = createSlice({
    name: 'terminal',
    initialState,
    reducers: {
        setTerminalDataCity(state, action) {
            state.dataCity = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setTerminal.fulfilled, (state, action) => {
            state.terminalData = action.payload
        })
        builder.addCase(getTerminal.fulfilled, (state, action) => {
            state.terminalData = action.payload
        })
        builder.addCase(refTerminal.fulfilled, (state, action) => {
            state.terminalData = action.payload
        })
        builder.addCase(getTerminalCity.fulfilled, (state, action) => {
            state.dataCity = action.payload
        })
        builder.addCase(findTerminalChildCity.fulfilled, (state, action) => {
            state.dataChildCity = action.payload
        })
    },
});
export default terminalSlice.reducer;
export const {setTerminalDataCity} = terminalSlice.actions
